<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#FDEAE8" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 24C27.3191 24 29.6045 21.7614 29.6045 19C29.6045 16.2386 27.3191 14 24 14C21.6809 14 19.3955 16.2386 19.3955 19C19.3955 21.7614 21.6809 24 24 24ZM16.5327 32.0122C17.3539 27.9981 20.6092 25 24 25C26.0338 25 27.4688 25.4659 28.6949 26.2763C26.2633 27.0424 24 29.3153 24 32V34H18.3746C17.247 34 16.3111 33.0953 16.5327 32.0122ZM29.5 28C30.0523 28 30.5 28.4477 30.5 29V30H31.5C32.0523 30 32.5 30.4477 32.5 31C32.5 31.5523 32.0523 32 31.5 32H30.5V33C30.5 33.5523 30.0523 34 29.5 34C28.9477 34 28.5 33.5523 28.5 33V32H27.5C26.9477 32 26.5 31.5523 26.5 31C26.5 30.4477 26.9477 30 27.5 30H28.5V29C28.5 28.4477 28.9477 28 29.5 28Z"
      fill="#B82025"
    />
  </svg>
</template>
